import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 1199 1199"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("defs", null, [
      _cache[1] || (_cache[1] = _createElementVNode("clipPath", { id: "clippath" }, [
        _createElementVNode("path", {
          d: "M0 0h1199v1199H0z",
          class: "cls-1"
        })
      ], -1)),
      _cache[2] || (_cache[2] = _createElementVNode("clipPath", { id: "clippath-1" }, [
        _createElementVNode("path", {
          d: "M0 0h1199v1199H0z",
          class: "cls-1"
        })
      ], -1)),
      (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(".cls-1{fill:none}.cls-3{fill:#fff}")
        ])),
        _: 1
      }))
    ]),
    _cache[3] || (_cache[3] = _createStaticVNode("<g id=\"Layer_1\" data-name=\"Layer 1\"><g id=\"Masku_klubi_RGB\" data-name=\"Masku klubi RGB\" style=\"clip-path:url(#clippath);\"><path d=\"M0 0h1199v1199H0z\" style=\"fill:#ef1c35;\"></path><path d=\"M902.89 787.01v-258.9l-85.36 183.24c-12.36 26.53-38.98 43.5-68.25 43.5H452.21c-29.27 0-55.89-16.97-68.25-43.5L298.6 528.11v258.9H116.09V125.04h191.69l211.89 447.31h162.14L893.7 125.04h191.69v661.97H902.88Z\" class=\"cls-3\" style=\"clip-path:url(#clippath-1);\"></path></g></g><path id=\"Isolation_Mode\" fill=\"currentColor\" d=\"M118.13 861.58h41.73v61.78h22.52l73.96-61.78h53.72l-93.38 78.3 102.48 82.43h-59.5l-79.13-64.67h-20.66v64.67h-41.73V861.57Zm235.51 0h41.94V987.4h117.14v34.92H353.64zM549 953.52v-91.94h41.94v85.95c0 38.64 4.75 42.97 54.75 42.97s54.75-4.34 54.75-42.97v-85.95h41.94v91.94c0 56.82-16.32 71.9-96.69 71.9S549 1010.34 549 953.52m247.5-91.94h122.72c49.17 0 63.84 11.16 63.84 41.73 0 20.87-7.02 30.58-25.62 35.54v.83c22.52 4.75 30.99 15.08 30.99 38.43 0 32.23-16.32 44.21-68.8 44.21H796.49V861.58Zm123.14 63.01c17.35 0 22.11-2.89 22.11-15.08s-4.96-15.5-22.31-15.5h-81.4v30.58h81.61Zm0 65.29c21.49 0 26.86-3.31 26.86-16.94s-5.58-16.74-26.86-16.74h-81.61v33.68zm118.77-128.3h41.94v160.74h-41.94z\" class=\"cls-3\" data-name=\"Isolation Mode\"></path>", 2))
  ]))
}
export default { render: render }